<template>
    <div class="Targetusers">
        <div class="rana_con">
            <div>
                <div class="rana_con_title">
                    {{form.title}}
                </div>
                <div class="Target_A clearfix">
                    <div class="fl Target_left">
                        <div class="_left_title">
                            <span>场景描述</span>
                        </div>
                        <div class="_left_kuang" :title="form.cjms">
                            <div class="_left_kuang_div" v-if="order==0">
                                {{form.value.cjms}}
                            </div>
                            <div class="_left_kuang_div" v-else>
                                <el-input type="textarea" maxlength="180" v-model="form.value.cjms" placeholder="请输入场景描述"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="Target_right fl">
                        <div class="_right_one marginR20 fl">
                            <div class="_rig_img">
                                <span>情景时态</span>
                            </div>
                            <div class="tense_div">
                                <el-checkbox v-model="item.val" :disabled="order==0?true:false"
                                    v-for="(item,index) in form.value.qjst" 
                                    :label="item.text" 
                                    :key="index">
                                    {{item.text}}
                                </el-checkbox>
                            </div>
                        </div>
                        <div class="_right_one marginR20 fl">
                            <div class="_rig_img">
                                <span>空间</span>
                            </div>
                            <div class="space_div" :title="form.kj" v-if="order==0">
                                {{form.value.kj}}
                            </div>
                            <div class="space_div" v-else>
                                <el-input type="textarea" maxlength="40" v-model="form.value.kj" placeholder="请输入空间"></el-input>
                            </div>
                        </div>
                        <div class="_right_one marginR20 fl">
                            <div class="_rig_img">
                                <span>事件</span>
                            </div>
                            <div class="space_div" :title="form.value.sj" v-if="order==0">
                                {{form.value.sj}}
                            </div>
                            <div class="space_div" v-else>
                                <el-input type="textarea" maxlength="40" v-model="form.value.sj" placeholder="请输入事件"></el-input>
                            </div>
                        </div>
                        <div class="_right_arrow marginR20 fl">
                            <img src="./../../../assets/img/arrow_1.png" alt="">
                        </div>
                        <div class="_right_one fl">
                            <div class="_rig_img">
                                <span>预测行为</span>
                            </div>
                            <div class="space_div" :title="form.value.ycxwjh" v-if="order==0">
                                {{form.value.ycxwjh}}
                            </div>
                            <div class="space_div" v-else>
                                <el-input type="textarea" maxlength="40" v-model="form.value.ycxwjh" placeholder="请输入预测行为"></el-input>
                            </div>
                        </div>
                        <div class="_right_two marginR20 fl">

                        </div>
                        <div class="_right_two marginR20 fl">
                            
                        </div>
                        <div class="_right_two marginR20 fl">
                            <img src="./../../../assets/img/arrow_2.png" alt="">
                            <span class="_right_b_span">通过</span>
                        </div>
                        <div class="_right_arrows marginR20 fl">

                        </div>
                        <div class="_right_two fl">
                            
                        </div>
                        <div class="_right_one fr" style="margin-right:14px">
                            <div class="_rig_img">
                                <span>干预假设结果</span>
                            </div>
                            <div class="space_div" :title="form.value.gyjs" v-if="order==0">
                                {{form.value.gyjs}}
                            </div>
                            <div class="space_div" v-else>
                                <el-input type="textarea" maxlength="40" v-model="form.value.gyjs" placeholder="请输入干预假设结果"></el-input>
                            </div>
                        </div>
                        <div class="_right_arrow marginR20 fr">
                            <img src="./../../../assets/img/arrow_3.png" alt="">
                            <span class="_righ_a_san">干预</span>
                        </div>
                        <div class="_right_one marginR20 fr">
                            <div class="_rig_img">
                                <span>关键能力</span>
                            </div>
                            <div class="space_div" :title="form.value.gjnl" v-if="order==0">
                                {{form.value.gjnl}}
                            </div>
                            <div class="space_div" v-else>
                                <el-input type="textarea" maxlength="40" v-model="form.value.gjnl" placeholder="请输入关键能力"></el-input>
                            </div>
                        </div>
                        <div class="_right_arrow marginR20 fr">
                            <img src="./../../../assets/img/arrow_3.png" alt="">
                            <span class="_righ_a_san">提供</span>
                        </div>
                        <div class="_right_one marginR20 fr">
                            <div class="_rig_img">
                                <span>产品预想</span>
                            </div>
                            <div class="space_div" :title="form.value.cpyx" v-if="order==0">
                                {{form.value.cpyx}}
                            </div>
                            <div class="space_div" v-else>
                                <el-input type="textarea" maxlength="40" v-model="form.value.cpyx" placeholder="请输入产品预想"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="T_butt_div">
                    <div class="T_button_one" v-preventReClick @click="prevstep()">
                        <span>上一步</span>
                    </div>
                    <div class="T_button_two" v-preventReClick @click="nextBotn()">
                        <span>下一步</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            order:0,
            checkList:[],
            form:{
                title:'',
                size:'',
                node:'',
                value:{
                    cjms:'',
                    sj:'',
                    kj:'',
                    ycxwjh:'',
                    qjst:[],
                    gyjs:'',
                    gjnl:'',
                    cpyx:''
                }
            }
        }
    },
    methods: {
        getapp(){
            if(this.order == 0){
                this.axios.projectS3P1({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                }).then(res=>{
                    if(res.data.code==0){
                        var str = res.data.data.qrcode
                        this.$emit('info', str);
                        var arr = res.data.data.data
                        arr.value.qjst.map(el=>{
                            if(el.val==1){
                                el.val = true
                            }else if(el.val == 0){
                                el.val = false
                            }
                        })
                        this.form = arr
                    }else{
                        this.form = {}
                    }
                }).catch(err=>{

                })
            }else if(this.order == 1){
                this.axios.projectS3P2({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                }).then(res=>{
                    if(res.data.code==0){
                        var str = res.data.data.qrcode
                        this.$emit('info', str);
                        let arr1 = res.data.data.data
                        arr1.value.qjst.map(el=>{
                            if(el.val==1){
                                el.val = true
                            }else if(el.val == 0){
                                el.val = false
                            }
                        })
                        this.form = arr1
                    }else{
                        this.form = {}
                    }
                }).catch(err=>{

                })
            }else if(this.order == 2){
                this.axios.projectS3P3({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                }).then(res=>{
                    if(res.data.code==0){
                        var str = res.data.data.qrcode
                        this.$emit('info', str);
                        var arr2 = res.data.data.data
                        arr2.value.qjst.map(el=>{
                            if(el.val==1){
                                el.val = true
                            }else if(el.val == 0){
                                el.val = false
                            }
                        })
                        this.form = arr2
                    }else{
                        this.form = {}
                    }
                }).catch(err=>{

                })
            }else if(this.order == 3){
                this.axios.projectS3P4({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                }).then(res=>{
                    if(res.data.code==0){
                        var str = res.data.data.qrcode
                        this.$emit('info', str);
                        var arr3 = res.data.data.data
                        arr3.value.qjst.map(el=>{
                            if(el.val==1){
                                el.val = true
                            }else if(el.val == 0){
                                el.val = false
                            }
                        })
                        this.form = arr3
                    }else{
                        this.form = {}
                    }
                }).catch(err=>{

                })
            }else if(this.order == 4){
                this.axios.projectS3P5({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                }).then(res=>{
                    if(res.data.code==0){
                        var str = res.data.data.qrcode
                        this.$emit('info', str);
                        var arr4 = res.data.data.data
                        arr4.value.qjst.map(el=>{
                            if(el.val==1){
                                el.val = true
                            }else if(el.val == 0){
                                el.val = false
                            }
                        })
                        this.form = arr4
                    }else{
                        this.form = {}
                    }
                }).catch(err=>{

                })
            }else if(this.order == 5){
                this.axios.projectS3P6({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                }).then(res=>{
                    if(res.data.code==0){
                        var str = res.data.data.qrcode
                        this.$emit('info', str);
                        var arr5 = res.data.data.data
                        arr5.value.qjst.map(el=>{
                            if(el.val==1){
                                el.val = true
                            }else if(el.val == 0){
                                el.val = false
                            }
                        })
                        this.form = arr5
                    }else{
                        this.form = {}
                    }
                }).catch(err=>{

                })
            }
        },
        nextBotn(){
            if(this.order<=this.form.size){
                this.form.value.qjst.map(el=>{
                    if(el.val==true){
                        el.val = 1
                    }else if(el.val == false){
                        el.val = 0
                    }
                })
                this.axios.Sets3({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:this.form.node,
                    value:this.form.value
                }).then(res=>{
                    if(res.data.code==0){
                        if(this.order<this.form.size){
                            this.order++
                            this.getapp()
                        }else{
                            this.$router.push({name:'StudentPage'})
                        }
                    }else{
                        this.$message.error(res.data.message)
                        this.form.value.qjst.map(el=>{
                            if(el.val==1){
                                el.val = true
                            }else if(el.val == 0){
                                el.val = false
                            }
                        })
                    }
                })
                
            }else{
                this.$router.push({name:'StudentPage'})
            }
        },
        prevstep(){
            if(this.order<1){
                this.$router.push({name:'StudentPage'})
            }else{
                this.order--
                this.getapp()
            }  
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/student/A/Targetusers.css';
</style>
<style>
._left_kuang_div .el-textarea, .space_div .el-textarea{
    width: 100%;
    height: 100%;
}
._left_kuang_div .el-textarea__inner{
    width: 100%;
    height: 100%;
    line-height: 1.5;
    padding:0;
    background: transparent;
    border: none;
    resize: none;
    color: #fff;
    text-align: justify;
    text-justify: distribute;
    word-break:break-all;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:14;/**显示的行数**/
    overflow:hidden;/**隐藏超出的内容**/
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
.space_div .el-textarea__inner{
    width: 100%;
    height: 100%;
    line-height: 1.5;
    padding:0;
    background: transparent;
    border: none;
    resize: none;
    color: #fff;
    text-align: justify;
    text-justify: distribute;
    word-break:break-all;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:4;/**显示的行数**/
    overflow:hidden;/**隐藏超出的内容**/
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
.tense_div .el-checkbox__label{
    color: #fff;
    font-size: 12px;
    padding-left: 5px;
}
.tense_div .el-checkbox__input.is-checked .el-checkbox__inner, .tense_div .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background: #fff;
    border-color: #fff;
}
.tense_div .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
    border-color: #000;
}
.tense_div .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #fff;
}
.tense_div .el-checkbox__inner{
    width: 12px;
    height: 12px;
}
.tense_div .el-checkbox__inner::after{
    height: 6px;
    left: 3px;
    border: 1px solid #000;
    border-left: 0;
    border-top: 0;
}
.tense_div .el-checkbox__inner:hover{
    border-color: #fff;
}
.tense_div .el-checkbox{
    margin-right: 15px;
}
</style>